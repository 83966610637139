// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-product-page-index-js": () => import("../src/templates/ProductPage/index.js" /* webpackChunkName: "component---src-templates-product-page-index-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agb-js": () => import("../src/pages/agb.js" /* webpackChunkName: "component---src-pages-agb-js" */),
  "component---src-pages-datenschutzerklaerung-js": () => import("../src/pages/datenschutzerklaerung.js" /* webpackChunkName: "component---src-pages-datenschutzerklaerung-js" */),
  "component---src-pages-impressum-js": () => import("../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-produkte-js": () => import("../src/pages/produkte.js" /* webpackChunkName: "component---src-pages-produkte-js" */),
  "component---src-pages-ueber-uns-js": () => import("../src/pages/ueber-uns.js" /* webpackChunkName: "component---src-pages-ueber-uns-js" */),
  "component---src-pages-versand-js": () => import("../src/pages/versand.js" /* webpackChunkName: "component---src-pages-versand-js" */),
  "component---src-pages-widerrufsbelehrung-js": () => import("../src/pages/widerrufsbelehrung.js" /* webpackChunkName: "component---src-pages-widerrufsbelehrung-js" */)
}

